<template>
	<div>
		<recovery-component />
	</div>
</template>
<script>
import RecoveryComponent from '@/components/system/Auth/Recovery'
export default {
	components: {
		RecoveryComponent,
	},
}
</script>
